import { FC, memo, useState } from 'react';
import s from './AppHeader.module.scss';
import { Burger } from '@/shared/UI/Burger/Burger';
import { Menu } from '@/widgets/AppHeader/ui/Menu/Menu';
import { useMediaQuery } from 'usehooks-ts';
import { DESKTOP_MEDIA_QUERY } from '@/shared/const/reponsive.const';
import { Link } from 'react-router-dom';
import { router } from '@/app/router/const/router';
import { Logo } from '@/shared/UI/Logo/Logo';
import { menu } from '@/widgets/AppHeader/model/const/menu';
import { SocialList } from '@/entities/Social';

interface PropsTypes {}

export const AppHeader: FC<PropsTypes> = memo(() => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  return (
    <>
      <div className={s.AppHeader}>
        <div className="container">
          <div className={s.wrapper}>
            <div className={s.left}>
              <Link className={s.logoLink} to={router.main()}>
                <Logo />
              </Link>
            </div>
            {/*<div className={s.title}>*/}
            {/*  Mis <br /> Zhytomyr*/}
            {/*</div>*/}
            <div className={s.right}>
              {isDesktop ? (
                <>
                  <SocialList />
                  <ul className={s.menuList}>
                    {menu.map(item => (
                        <li className={s.item} key={item.value}>
                          {(item.route.includes(location.pathname) && item.route.includes('#')) ? (
                              <a href={item.route} className={s.link}>
                                {item.name}
                              </a>
                          ) : (
                              <Link to={item.route} className={s.link}>
                                {item.name}
                              </Link>
                          )}
                        </li>
                    ))}
                  </ul>
                </>
              ) : (
                <Burger onClick={() => setIsMenuOpened(!isMenuOpened)} />
              )}
              {/*  {isTablet && <Button className={s.btn} href={router.votes()}>проголосувати</Button>}*/}
            </div>
          </div>
        </div>
      </div>

      {isMenuOpened && <Menu onClose={() => setIsMenuOpened(false)} />}
    </>
  );
});
