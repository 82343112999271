import { FC, memo } from "react";
import s from './Logo.module.scss'
import LogoImg from '@/shared/assets/logo.svg?react'

interface PropsTypes {

}
export const Logo: FC<PropsTypes> = memo(() => {
  return (
    <div className={s.Logo}>
        <LogoImg className={s.logo}/>
        <div className={s.logoText}>Mis Zhytomyr</div>
    </div>
  );
});